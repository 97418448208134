<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="cdn-list">
            <ion-list-header>Blacktie-CDN</ion-list-header>
            <ion-note v-if="user">{{ user.email }}</ion-note>

            <ion-menu-toggle auto-hide="false" v-if="user">
              <ion-item
                button
                router-direction="root"
                :router-link="`/storage/#/${store.state.currentStorageZone.storagezone_name}`"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: isSelected('/storage/') }"
                v-if="curStor"
              >
                <ion-icon
                  slot="start"
                  :ios="mailOutline"
                  :md="mailSharp"
                ></ion-icon>
                <ion-label class="large-font">Storage Zones</ion-label>
              </ion-item>
              <ion-item
                button
                lines="none"
                detail="false"
                class="hydrated"
                @click="toggleFileMode"
              >
                <ion-icon
                  slot="start"
                  :ios="fileTrayStackedOutline"
                  :md="fileTrayStackedOutline"
                ></ion-icon>
                <ion-label class="large-font"
                  >File Mode :
                  {{ fileMode === 0 ? "List View" : "Gallery View" }}</ion-label
                >
              </ion-item>
              <ion-item
                button
                v-if="user.admin !== 1"
                router-direction="root"
                :router-link="`/shared/`"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: isSelected('/shared/') }"
              >
                <ion-icon
                  slot="start"
                  :ios="paperPlaneOutline"
                  :md="paperPlaneSharp"
                ></ion-icon>
                <ion-label class="large-font">Shared With Me</ion-label>
              </ion-item>
              <ion-item
                button
                router-direction="root"
                router-link="/change-password"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: isSelected('/change-password') }"
              >
                <ion-icon
                  slot="start"
                  :ios="refreshOutline"
                  :md="refreshOutline"
                ></ion-icon>
                <ion-label class="large-font">Change Password</ion-label>
              </ion-item>
              <ion-item
                button
                @click="logout()"
                router-direction="root"
                router-link="/login"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: isSelected('/login') }"
              >
                <ion-icon
                  slot="start"
                  :ios="logOutOutline"
                  :md="logOutOutline"
                ></ion-icon>
                <ion-label class="large-font">Logout</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false" v-if="!user">
              <ion-item
                router-direction="root"
                router-link="/login"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: isSelected('/login') }"
              >
                <ion-icon
                  slot="start"
                  :ios="logInOutline"
                  :md="logInOutline"
                ></ion-icon>
                <ion-label class="large-font">Login </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  logInOutline,
  logOutOutline,
  refreshOutline,
  fileTrayStackedOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const store = useStore();
    const selectedIndex = ref(0);

    const route = useRoute();

    return {
      selectedIndex,
      mailSharp,
      mailOutline,
      paperPlaneOutline,
      paperPlaneSharp,
      logInOutline,
      logOutOutline,
      refreshOutline,
      fileTrayStackedOutline,
      isSelected: (url) => (url === route.path ? "selected" : ""),
      user: computed(() => store.state.user),
      curStor: computed(() => store.state.currentStorageZone),
      fileMode: computed(() => store.state.fileMode),
      store,
      toggleFileMode: () => store.dispatch("toggleFileMode"),
      logout: () => store.dispatch("logout"),
    };
  },
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#cdn-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#cdn-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
