import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "../store/index";
import { showToast, showLoading } from "@/helpers/toastLoadHelper.js";

const routes = [
  {
    path: "",
    redirect: "/storage/",
    meta: { requiresLogin: true },
  },
  {
    path: "/storage/",
    component: () => import("../views/FolderPage.vue"),
    meta: { requiresLogin: true },
    beforeEnter: async (to, _from, next) => {
      const urlStoragezone = to.hash.substring(1).split("/")[1];
      if (urlStoragezone) {
        const storagezone = store.state.user.allowed.find(
          (storage) => storage.storagezone_name === urlStoragezone
        );
        if (!storagezone) {
          showToast("Invalid Url Or No Permission", "warning");
          next("/");
        } else {
          await store.dispatch("setCurrentStorageZone", storagezone);
          next();
        }
      } else {
        next({
          path: "/",
          hash: `#/${store.state.currentStorageZone.storagezone_name}`,
        });
      }
    },
  },
  {
    path: "/shared/",
    component: () => import("../views/SharedPage.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/shared-folder/:id",
    component: () => import("../views/SharedFolderPage.vue"),
    meta: { requiresLogin: true },
    props: true,
  },
  {
    path: "/change-password",
    component: () => import("../views/ChangePassword.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/login",
    component: () => import("../views/UserLogin.vue"),
    beforeEnter: async (to, from, next) => {
      if (store.state.user) {
        next({
          path: "/",
        });
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.initialize) {
    const loading = await showLoading("Please wait...");
    await store.dispatch("refreshToken");
    loading.dismiss();
    store.state.initialize = 1;
  }
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (!store.state.user) {
      showToast("Please Login", "warning");
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
