import { toastController, loadingController } from "@ionic/vue";

export async function showToast(message, color, duration = 4000) {
  const toast = await toastController.create({
    message,
    color,
    duration,
  });
  toast.present();
}
export async function showLoading(message, duration = 10000) {
  const loading = await loadingController.create({
    message,
    duration,
  });
  await loading.present();
  return loading;
}
