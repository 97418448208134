import axios from "axios";
import router from "../router/index";
import { showToast } from "@/helpers/toastLoadHelper.js";
import jwt_decode from "jwt-decode";

const auth = {
  state: {
    user: null,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
      state.currentStorageZone = null;
      state.initialize = 0;
    },
    UNSET_USER: (state) => {
      state.user = null;
    },
  },
  actions: {
    login: async ({ commit, dispatch }, cred) => {
      dispatch("setGlobalLoading", { status: true, message: "Logging In..." });
      try {
        const result = await axios.post(`/auth/attempt`, cred);
        const { user } = jwt_decode(result.data.access_token);
        commit("SET_USER", user);
        showToast("Login Successful", "success");
        if (user.allowed.length) {
          commit("SET_CURRENT_STORAGE_ZONE", user.allowed[0]);

          router.push({
            path: "/storage/",
            hash: `#/${user.allowed[0].storagezone_name}`,
          });
        } else {
          router.push({
            path: "/shared/",
          });
        }
      } catch (err) {
        showToast(
          "Login failed. Please Check Your Credentials",
          "danger",
          6000
        );
        console.log(err);
        dispatch("setGlobalLoading", { status: false });
      }
    },
    logout: ({ commit }) => {
      axios
        .post(`/auth/logout`)
        .then(() => {
          commit("UNSET_USER");
          router.push("/login");
          router.go(0);
          showToast("Logged Out", "success", 6000);
        })
        .catch(() => {
          showToast("Logging Out Failed", "danger", 4000);
        });
    },
    refreshToken: async ({ commit, state, dispatch }) => {
      try {
        const result = await axios.post(`/auth/refresh`);
        if (result.data) {
          const { user } = jwt_decode(result.data.access_token);
          commit("SET_USER", user);
          commit("SET_CURRENT_STORAGE_ZONE", user.allowed[0]);
        } else {
          throw "No Token";
        }
      } catch (err) {
        if (state.user) {
          dispatch("logout");
        }
      }
    },
  },
};

export default auth;
